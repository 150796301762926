<template>
  <div>
    <validation-observer ref="simpleRules">
      <div id="component-breadcrumbs">
        <b-breadcrumb
          class="breadcrumb-slash"
          :items="breadcrumbItems"
        />
      </div>

      <b-form class="p-2">
        <div class="accordion">
          <div
            class="accordion-title"
            @click="toggleAccordion('DETAILS')"
          >
            <div
              class="collapse-arrow"
              :class="{open: tab.DETAILS}"
            />
            <h3>Branch Details</h3>
          </div>
          <b-row v-if="tab.DETAILS">
            <b-col
              md="12"
              class="pr-6"
            >
              <b-row class="pb-2 pt-2">
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Branch Name"
                    rules="required"
                  >
                    <b-form-group
                      label="Branch Name"
                      label-for="branchname"
                      class="required-data"
                    >
                      <b-form-input
                        id="branchname"
                        v-model="branchObject.Name"
                        placeholder=""
                        name="branchname"
                        :state="errors.length > 0 ? false:null"
                        @keypress.enter.prevent
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col v-if="branchObject.BranchID">
                  <b-form-group
                    label="Branch ID"
                    label-for="branchid"
                  >
                    <b-form-input
                      id="branchid"
                      v-model="branchObject.BranchID"
                      placeholder=""
                      name="branchid"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pb-2 pt-2">
                <b-col md="2">
                  <b-form-group
                    label="Account Manager"
                    label-for="accountManager"
                  >
                    <b-form-input
                      id="accountManager"
                      v-model="AMName"
                      placeholder=""
                      name="accountManager"
                      :readonly="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Branch Account Manager"
                    label-for="csr"
                  >
                    <v-select
                      id="csr"
                      v-model="CSRUserID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="CSRoption"
                      placeholder=""
                      name="csr"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Deal Owner"
                    label-for="dealOwner"
                  >
                    <v-select
                      id="dealOwner"
                      v-model="DealOwnerUserID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="dealOwner"
                      placeholder=""
                      name="dealOwner"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <v-select
                      id="status"
                      v-model="CRMStatus"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="option"
                      placeholder=""
                      name="status"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="pb-2 pt-2">
                <b-col md="6">
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        name="Street Address"
                        rules="required"
                      >
                        <b-form-group
                          label="Street Address"
                          label-for="streetaddress"
                          class="required-data"
                        >
                          <b-form-input
                            id="streetaddress"
                            v-model="branchObject.Address1"
                            placeholder=""
                            name="streetaddress"
                            :state="errors.length > 0 ? false:null"
                            @keypress.enter.prevent
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Address Line 2"
                        label-for="addressline2"
                      >
                        <b-form-input
                          id="addressline2"
                          v-model="branchObject.Address2"
                          placeholder=""
                          name="addressline2"
                          @keypress.enter.prevent
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="5">
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-group
                          label="City"
                          label-for="city"
                          class="required-data"
                        >
                          <b-form-input
                            id="city"
                            v-model="branchObject.City"
                            placeholder=""
                            name="city"
                            :state="errors.length > 0 ? false:null"
                            @keypress.enter.prevent
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="3">
                      <b-form-group
                        label="State"
                        label-for="state"
                        class="required-data"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          rules="required"
                        >
                          <v-select
                            id="state"
                            v-model="branchObject.StateProvince"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="StatesList"
                            placeholder=""
                            name="state"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Zip"
                        rules="required"
                      >
                        <b-form-group
                          label="Zip"
                          label-for="zip"
                          class="required-data"
                        >
                          <ZipInput
                            id="zip"
                            v-model="branchObject.ZipCode"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Address Type"
                        label-for="addresstype"
                      >
                        <v-select
                          id="addresstype"
                          v-model="AddressTypeText"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="addressTypeOption"
                          placeholder=""
                          name="addresstype"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Phone 1"
                        label-for="phone1"
                      >
                        <b-form-input
                          id="phone1"
                          v-model="branchObject.Phone1"
                          v-mask="'(###) ###-####'"
                          placeholder=""
                          name="phone1"
                          @keypress.enter.prevent
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Phone 2"
                        label-for="phone2"
                      >
                        <b-form-input
                          id="phone2"
                          v-model="branchObject.Phone2"
                          v-mask="'(###) ###-####'"
                          placeholder=""
                          name="phone2"
                          @keypress.enter.prevent
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="empty-row" />
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Type"
                        label-for="phonetype1"
                      >
                        <v-select
                          id="phonetype1"
                          v-model="Phone1TypeText"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="phoneTypeOption"
                          placeholder=""
                          name="phonetype1"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Type"
                        label-for="phonetype2"
                      >
                        <v-select
                          id="phonetype2"
                          v-model="Phone2TypeText"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="title"
                          :options="phoneTypeOption"
                          placeholder=""
                          name="phonetype2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-form-group
                label="Branch Billing Notes"
                label-for="branchBillingNotes"
              >

                <div
                  class="collapse-textarea"
                  :class="{expanded: !isExpandedBilling}"
                >
                  <textarea
                    id="branchBillingNotes"
                    v-model="branchObject.BillingNotes"
                    class="expandable-textarea"
                    :class="[isExpandedBilling ? 'not-expanded' : 'expanded']"
                    contentEditable
                  />
                  <div @click="isExpandedBilling = !isExpandedBilling">
                    <span
                      class="expand-arrow mt-n1"
                      :class="[isExpandedBilling ? 'not-expanded' : 'expanded']"
                    >&#187;</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="accordion">
          <div
            class="accordion-title"
            @click="toggleAccordion('INSTRUCTIONS')"
          >
            <div
              class="collapse-arrow"
              :class="{open: tab.INSTRUCTIONS}"
            />
            <h3>Special Instructions</h3>
          </div>
          <b-row v-if="tab.INSTRUCTIONS">
            <b-col
              md="12"
              class="pr-6"
            >
              <b-row class="mt-3">
                <b-col>
                  <b-form-group
                    label="Special Instructions Investigator"
                    label-for="specinstructinvestigator"
                  >

                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedInvestigatorInstructions}"
                    >
                      <textarea
                        id="specinstructinvestigator"
                        v-model="branchObject.SpecialInstructionsInvestigator"
                        class="expandable-textarea"
                        :class="[isExpandedInvestigatorInstructions ? 'not-expanded' : 'expanded']"
                        contentEditable
                      />
                      <div @click="isExpandedInvestigatorInstructions = !isExpandedInvestigatorInstructions">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedInvestigatorInstructions ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <b-form-group
                    label="Special Instructions Manager"
                    label-for="specinstructmanager"
                  >

                    <div
                      class="collapse-textarea"
                      :class="{expanded: !isExpandedManagerInstructions}"
                    >
                      <textarea
                        id="specinstructmanager"
                        v-model="branchObject.SpecialInstructionsManager"
                        class="expandable-textarea"
                        :class="[isExpandedManagerInstructions ? 'not-expanded' : 'expanded']"
                        contentEditable
                      />
                      <div @click="isExpandedManagerInstructions = !isExpandedManagerInstructions">
                        <span
                          class="expand-arrow mt-n1"
                          :class="[isExpandedManagerInstructions ? 'not-expanded' : 'expanded']"
                        >&#187;</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <div class="accordion">
          <div
            class="accordion-title"
            @click="toggleAccordion('requestorS')"
          >
            <div
              class="collapse-arrow"
              :class="{open: tab.requestorS}"
            />
            <h3>Client Requestors</h3>
          </div>
          <b-row v-if="tab.requestorS">
            <b-col
              md="12"
              class="pr-6"
            >
              <div class="row mb-1 mt-3">
                <div class="col-6">
                  <b-form-group
                    label="Add Requestor"
                    label-for="addRequestor"
                    label-cols="3"
                  >
                    <v-select
                      id="addRequestor"
                      v-model="Requestor"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="requestorOption"
                      placeholder=""
                      name="addRequestor"
                    />
                  </b-form-group>
                </div>
                <div class="col-4">
                  <div>
                    <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
                      <b-button
                        block
                        variant="primary"
                        style=" justify-content: space-around; display: flex; border-radius: 0;"
                        :disabled="!branchObject.BranchID
                          || getCurrentScopes.indexOf('st2.editclientsbrachescontacts') === -1
                          || getCurrentScopes.indexOf('st2.editclientsandusers') === -1
                          || !Requestor || !Requestor.value"
                        @click="createNewrequestor"
                      >
                        <span>Save</span>
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <b-form-group>
                    <div class="d-flex align-items-right">
                      <label class="mr-1" />
                      <b-form-input
                        v-model="searchFilterrequestor"
                        placeholder="Filter requestors"
                        type="text"
                        class="d-inline-block"
                        @keypress.enter.prevent
                      />
                    </div>
                  </b-form-group>
                </div>

              </div>
              <vue-good-table
                :columns="columns"
                :rows="filterRows"
                :rtl="direction"
                :search-options="{
                  enabled: false,
                }"
                :pagination-options="{
                  enabled: false,
                  perPage: pageLength,
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Name -->
                  <span
                    v-if="props.column.field === 'NAME'"
                    class="text-nowrap"
                  >
                    <span class="text-nowrap">{{ props.row.Name }}</span>
                  </span>

                  <!-- Column: Email -->
                  <span
                    v-if="props.column.field === 'EMAIL'"
                    class="text-nowrap"
                  >
                    <a
                      class="text-nowrap"
                      :href="'mailto:' + props.row.EMail"
                    >{{ props.row.EMail }}</a>
                  </span>

                  <!-- Column: Status -->
                  <span v-else-if="props.column.field === 'STATUS'">
                    <span>
                      {{ props.row.Status }}
                    </span>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'ACTION'">
                    <span>
                      <b-dropdown
                        v-if="getCurrentScopes.indexOf('st2.editclientsbrachescontacts') !== -1
                          && getCurrentScopes.indexOf('st2.editclientsandusers') !== -1"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item>
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>
                            <router-link
                              :to="'/client/requestor/' + props.row.ID + '/edit/' + CurrentClient.ClientID + '/' + branchObject.BranchID"
                            >Edit</router-link>
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteRequestorWithPrompt(props.row)">
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                        {{ totalResultCount }}
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['100', '150', '200']"
                        class="mx-1"
                        @input="
                          (value) => {
                            props.perPageChanged({ currentPerPage: value });
                            onPageSizeChanged({ currentPerPage: value });
                          }
                        "
                      />
                      <span class="text-nowrap">per page</span>
                    </div>
                    <div>
                      <b-pagination
                        v-model="currentPage"
                        :value="1"
                        :total-rows="totalResultCount"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                        @page-click="onPageChanged"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>

        <b-row class="pb-2 pt-2">
          <b-col>
            <div class="d-flex justify-content-between">
              <div>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="resetForm()"
                >
                  Cancel
                </b-button>
              </div>
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="isLoading"
                  @click.prevent="validationForm()"
                >
                  Save
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete-requestor"
      ok-only
      ok-variant="danger"
      ok-title="Delete Requestor"
      modal-class="modal-danger"
      centered
      title="Confirm Delete requestor"
      @ok="confirmDeleteRequestor()"
    >
      <div v-if="requestorToDelete !== null">
        Are you sure you want to delete requestor {{ requestorToDelete.Name }} ? This cannot be
        undone.
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BBreadcrumb,
  BDropdown,
  BDropdownItem, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import "vue-good-table/dist/vue-good-table.css";
import vSelect from 'vue-select'
import { required } from '@validations'
import store from "@/store";
import {VueGoodTable} from "vue-good-table";
import {mapGetters, mapMutations} from "vuex";
import APIService from "@core/utils/APIService";
import ZipInput from "@core/components/zipInput/ZipInput.vue";

const apiService = new APIService();

export default {
  components: {
    ZipInput,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    vSelect,
    BBreadcrumb,
    BDropdown,
    BDropdownItem,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      branchObject: {},
      option: [{ title: 'Active', value: 'Active' }, { title: 'Inactive', value: 'Inactive' }, { title: 'Prospect', value: 'Prospect' }],
      addressTypeOption: [{ title: 'Business', value: 'Business' }, { title: 'Residential', value: 'Residential' }, { title: 'PO Box', value: 'PO Box' }],
      phoneTypeOption: [{ title: 'Office', value: 'Office' }, { title: 'Cell', value: 'Cell' }],
      StatesList: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA',
        'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME',
        'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM',
        'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX',
        'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'
      ],
      CSRoption: [],
      dealOwner: [],
      requestorToDelete: null,
      required,
      isLoading: false,

      requestorOption: [],
      Requestor: "",

      AMName: "",

      CRMStatus: { title: 'Active', value: 'Active' },
      DealOwnerUserID: "",
      CSRUserID: "",
      CurrentClient: {},
      AddressTypeText: "",
      Phone1TypeText: "",
      Phone2TypeText: "",

      isExpandedInvestigatorInstructions: false,
      isExpandedManagerInstructions: false,
      isExpandedBilling: false,
      pageLength: 20,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 20,
      totalResultCount: 0,
      tab: {
        DETAILS: true,
        INSTRUCTIONS: false,
        requestorS: false,
      },
      searchFilterrequestor: '',
      rows: [],
      filterRows: [],
      columns: [
        {
          label: "Name",
          field: "NAME",
        },
        {
          label: "Email",
          field: "EMAIL",
        },
        {
          label: "Status",
          field: "STATUS",
        },
        {
          label: "Action",
          field: "ACTION",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Clients',
          href: '/clients'
        },
        {
          text: this.CurrentClient.Name ? this.CurrentClient.Name : 'Clients',
          href: '/clients/view/' + this.CurrentClient.ClientID
        },
        {
          text: this.branchObject.BranchID ? this.branchObject.Name : 'Add Branch'
        }
      ]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
  },
  watch: {
    searchFilterrequestor(val) {
      if (val) {
        this.filterRows = this.rows.filter(i =>
            (i.Name.toUpperCase().indexOf(val.toUpperCase()) !== -1) ||
            (i.EMail.toUpperCase().indexOf(val.toUpperCase()) !== -1)
        )
      } else {
        this.filterRows = this.rows;
      }
    },
    Requestor(val) {
      if (val && val.title && !val.value)
        this.$router.push('/client/requestor/add/' + this.CurrentClient.ClientID + '/' + this.branchObject.BranchID)
    }
  },
  async created() {
    try {
      const response = await apiService.get('users/user').then(res => res.data).catch(error => error)
      this.dealOwner = this.CSRoption = response.Users.map(d => ({title: d.LastName + ", " + d.FirstName, value: d.UserAccountID}))
      if (this.dealOwner && this.CSRoption) {
        var existingBranchID = this.$route.params.branchID;
        var existingClientID = this.$route.params.clientID;
        if (existingClientID) {
          apiService
              .get("client/" + existingClientID + "/detail")
              .then((res) => {
                this.CurrentClient = {...this.CurrentClient, ...res.data};
                this.AMName = this.CSRoption.filter(i => i.value === res.data.AMUserID)[0].title || null;
                if (existingBranchID) {
                  this.getBranchData(existingBranchID)
                }
              })
        }
      }
    } catch (err) {
      this.error = err
    }
  },

  mounted() {},
  methods: {
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    getAllRequestors(clientID) {
      apiService
          .get("client/" + clientID + "/requestors")
          .then(res => {
            this.requestorOption = res.data.map(r => ({
              title: r.id + " - " + r.name + " - " + r.eMail,
              value: r.id
            }))
            this.requestorOption = this.requestorOption.filter(i => this.filterRows.every(r => r.ID !== i.value))
            this.requestorOption.unshift({
              title: "Add New Requestor...",
              value: 0
            })
          })
    },

    resetForm() {
      this.branchObject = {};
      this.$router.push('/clients/view/' + this.CurrentClient.ClientID);
    },

    processForm() {
      this.isLoading = true;
      let postData = {};
      postData.Name = this.branchObject.Name;
      postData.Address1 = this.branchObject.Address1;
      postData.Address2 = this.branchObject.Address2;
      postData.City = this.branchObject.City;
      postData.StateProvince = this.branchObject.StateProvince;
      postData.ZipCode = this.branchObject.ZipCode;
      postData.Phone1 = this.branchObject.Phone1;
      postData.Phone2 = this.branchObject.Phone2;
      postData.BillingNotes = this.branchObject.BillingNotes;
      postData.SpecialInstructionsInvestigator = this.branchObject.SpecialInstructionsInvestigator;
      postData.SpecialInstructionsManager = this.branchObject.SpecialInstructionsManager;
      postData.CRMStatus = (this.CRMStatus && this.CRMStatus.value) || (this.CRMStatus.length && this.CRMStatus[0].value) || null;
      postData.DealOwnerUserID = (this.DealOwnerUserID && this.DealOwnerUserID.value) || (this.DealOwnerUserID && this.DealOwnerUserID.length && this.DealOwnerUserID[0].value) || null;
      postData.CSRUserID = (this.CSRUserID && this.CSRUserID.value) || (this.CSRUserID.length && this.CSRUserID[0].value) || null;
      postData.Phone1TypeText = (this.Phone1TypeText && this.Phone1TypeText.value) || (this.Phone1TypeText.length && this.Phone1TypeText[0].value) || null;
      postData.Phone2TypeText = (this.Phone2TypeText && this.Phone2TypeText.value) || (this.Phone2TypeText.length && this.Phone2TypeText[0].value) || null;
      postData.AddressTypeText = (this.AddressTypeText && this.AddressTypeText.value) || (this.AddressTypeText.length && this.AddressTypeText[0].value) || null;
      postData.CustomerAccountNumber = "";
      postData.ClientID = this.CurrentClient.ClientID;

      var endpoint = 'client/branch/add';
      if (this.branchObject.BranchID) {
        endpoint = 'client/branch/' + this.branchObject.BranchID + '/edit';
      }

      apiService.post(endpoint, postData).then(response => {
        if (response) {
          this.showToast('success', 'top-center', 4000, 'Branch saved!');
          if (response.data && response.data.BranchID) {
            this.getBranchData(response.data.BranchID);
            this.$router.push('/clients/' + this.CurrentClient.ClientID + '/branch/' + response.data.BranchID + '/edit')
          }
        } else {
          this.isLoading = false;
        }
      })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.processForm()
        }
      })
    },

    createNewrequestor() {
      const postData = {
        BranchId: this.branchObject.BranchID,
        RequestorId: this.Requestor.value
      }
      apiService
          .post("client/branch/add-exist-requestor", postData)
          .then(res => {
            if (res) {
              this.getBranchData(this.BranchID)
              this.Requestor = "";
              this.getAllRequestors(this.CurrentClient.ClientID)
              this.showToast("success", "top-center", 4000, "Requestor added");
            } else {
              this.showToast("danger", "top-center", 4000, "Requestor not added");
            }
          })
    },

    deleteRequestorWithPrompt(requestorObj) {
      this.requestorToDelete = requestorObj;
      this.$bvModal.show("modal-delete-requestor");
    },

    confirmDeleteRequestor() {
      if (!this.requestorToDelete) {
        return;
      }
      const DeleteID = this.requestorToDelete.ID;
      apiService
          .delete("client/branch/" + this.branchObject.BranchID + "/requestor/" + this.requestorToDelete.ID)
          .then((resp) => {
            if (resp) {
              this.showToast("success", "top-center", 4000, "Requestor deleted");
              this.filterRows = this.rows.filter(r => r.ID !== DeleteID)
              this.getAllRequestors(this.CurrentClient.ClientID)
            }
          })
      this.requestorToDelete = null;
    },

    setCurrentClients() {
      const CurrentBranch = {
        Name: this.branchObject.Name,
        BranchID: this.branchObject.BranchID,
        DealOwnerUserID: this.branchObject.DealOwnerUserID,
      }
      this.CurrentClient = {...this.CurrentClient, CurrentBranch}
    },

    disabledBranch() {
      return !this.branchObject.BranchID
    },

    toggleAccordion(tab) {
      this.tab[tab] = !this.tab[tab]
    },

    onPageChanged(bvEvent, page) {
      console.log(page)
    },

    onPageSizeChanged(newPageSize) {
      console.log(newPageSize)
      this.pageLength = newPageSize.currentPerPage;
    },

    getBranchData(BranchID) {
      apiService
          .get("client/branch/" + BranchID + "/detail")
          .then((response) => {
            this.branchObject = response.data;
            this.BranchID = response.data.BranchID;
            this.DealOwnerUserID = this.dealOwner.filter(i => i.value === response.data.DealOwnerUserID) || null;
            this.CSRUserID = this.CSRoption.filter(i => i.value === response.data.CSRUserID) || null;
            this.CRMStatus = this.option.filter(i => i.value === response.data.CRMStatus) || null;
            this.Phone1TypeText = this.phoneTypeOption.filter(i => i.value === response.data.Phone1TypeText) || null;
            this.Phone2TypeText = this.phoneTypeOption.filter(i => i.value === response.data.Phone2TypeText) || null;
            this.AddressTypeText = this.addressTypeOption.filter(i => i.value === response.data.AddressTypeText) || null;
            this.setCurrentClients();
          });
      apiService
          .get("client/branch/"  + BranchID + "/requestors")
          .then((response) => {
            this.filterRows = this.rows = response.data;
            this.getAllRequestors(this.CurrentClient.ClientID)
          });
    },
  },
}
</script>

<style>
hr{
  border: 1px solid #ebe9f1;
}
.large-text label {
  font-size: 1.1rem;
}
.vs__selected {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.vs__selected-options {
  height: 2.1rem;
  width: 1rem;
}
.empty-row {
  height: 4rem;
}
.accordion-title h3 {
  color: white;
  font-weight: bold;
  margin: 0.5rem;
}
.accordion-title {
  background: #476dae;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accordion {
  margin-top: 0.2rem;
}
.collapse-arrow {
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
  border: 3px solid white;
  border-top: none;
  border-left: none;
  transform: rotate(-135deg);
  transition-duration: 200ms;
}
.collapse-arrow.open {
  transform: rotate(45deg);
}
</style>
